/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import style from "../styles/loaderIA.module.css";


const Loader = () => {
  const [message, setMessage] = useState("Enviando feedbacks a la IA ...");
  const [isVisible, setIsVisible] = useState(true);
  const spans = Array.from({ length: 14 }, (_, index) => index + 1);

  useEffect(() => {
    const timeouts = [];
    
    // Cambiar mensaje después de 5 segundos
    timeouts.push(setTimeout(() => changeMessage("Analizando feedbacks ..."), 2000));
    // Cambiar mensaje después de 15 segundos (10 segundos adicionales)
    timeouts.push(setTimeout(() => changeMessage("Generando respuesta ..."), 5000));

    return () => {
      // Limpiar timeouts al desmontar el componente
      timeouts.forEach(clearTimeout);
    };
  }, []);

  const changeMessage = (newMessage) => {
    setIsVisible(false);
    setTimeout(() => {
      setMessage(newMessage);
      setIsVisible(true);
    }, 500); // Deja tiempo para que se complete la animación de fade out antes de cambiar el mensaje
  };

  return (
    <div className={style.loader}>
      {spans.map((_, index) => (
        <span key={index}></span>
      ))}
      <div className={`${style.message} ${isVisible ? style.fadeIn : style.fadeOut}`}>
        {message}
      </div>
    </div>
  );
};

export default Loader;
