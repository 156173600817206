import React, { useState } from "react";
import mainStyle from '../styles/mainComponents.module.css';
import useFetch from "../hooks/useFetch";
import StepOne from "../components/homeComponents/StepOne";
import StepTwo from "../components/homeComponents/StepTwo";
import Loader from "../components/Loader";

const Home = () => {
  const { execute: execute1 } = useFetch();
  const { execute: executeFeedback } = useFetch();
  const [step, setStep] = useState(1); 
  const [respuesta, setRespuesta] = useState(""); 
  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    email: ""
  });
  const [challenge, setChallenge] = useState(null); 
  const [loading, setLoading] = useState(false);

  const handleNextStep = () => {
    if (respuesta.trim() === "") {
      alert("Por favor, ingresa una respuesta.");
    } else {
      setStep(2); 
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const fetchApiDataPost = async () => {
    const apiUrl = process.env.REACT_APP_API_URL || "https://tryapi.feelinback.com";
    const body = {
      nombre: formData.nombre,
      apellido: formData.apellido,
      email: formData.email,
      respuesta: respuesta, 
    };

    setLoading(true);  // Inicia el loader cuando se comienza a enviar la solicitud

    try {
      const response = await execute1(`${apiUrl}/personas/postRespuesta`, "POST", body);
      fetchIAFeedback(response.data.id_datosPruebaIa);  // Llamamos al siguiente paso para cargar el análisis
    } catch (err) {
      console.error("Error al enviar los datos:", err.message);
      setLoading(false);  // Detén el loader si hay un error
    }
  };

  const fetchIAFeedback = async (id_datosPruebaIa) => {
    const apiUrl = process.env.REACT_APP_API_URL || "https://tryapi.feelinback.com";
    const body = {
      id_datosPruebaIa: id_datosPruebaIa,
    };

    try {
      const response = await executeFeedback(`${apiUrl}/personas/feedbackIApersonal`, "POST", body);
      setChallenge(response.data.challengeObject); 
      setLoading(false);  // Detén el loader cuando se complete el análisis
    } catch (err) {
      console.error("Error al obtener el feedback de la IA:", err.message);
      setLoading(false);  // Detén el loader si hay un error
    }
  };

// handleSubmit.js
    const handleSubmit = (e) => {
      e.preventDefault();
      if (formData.nombre && formData.apellido && formData.email) {
        // Cambiamos a step 3 al enviar el formulario
        setStep(3);
        fetchApiDataPost();  // Enviamos los datos
      }
    };

  const handleGoToDemo = () => {
    window.open('https://app.feelinback.com/register', '_blank');
  }


  function removeLabel(text) {
    if (typeof text !== 'string') {
      return '';
    }
    const splitText = text.split(": ");
    if (splitText.length > 1) {
      return splitText.slice(1).join(": ");
    }
    return text;
  }
  
  const removeIcon = (text) => {
    if (typeof text !== 'string') {
      return "";
    }
    
    const iconRegex = /^[^\w\s]/;
    while (iconRegex.test(text[0])) {
      text = text.slice(1).trim();
    }
    
    return text;
  };
  
  const IconoSVG1 = () => (
    <svg className={mainStyle.svgIA} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm.09-100q-91.63 0-155.86-64.14Q260-388.28 260-479.91q0-91.63 64.14-155.86Q388.28-700 479.91-700q91.63 0 155.86 64.14Q700-571.72 700-480.09q0 91.63-64.14 155.86Q571.72-260 480.09-260Zm-.09-60q66 0 113-47t47-113q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-100q-24.54 0-42.27-17.73Q420-455.46 420-480q0-24.54 17.73-42.27Q455.46-540 480-540q24.54 0 42.27 17.73Q540-504.54 540-480q0 24.54-17.73 42.27Q504.54-420 480-420Z"/></svg>

  );
  
  const IconoSVG2 = () => (
    <svg className={mainStyle.svgIA2} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M100-580.77v-157.69q0-30.31 21-51.31 21-21 51.31-21h615.38q30.31 0 51.31 21 21 21 21 51.31v157.69h-60v-157.69q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H172.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v157.69h-60Zm72.31 330q-30.31 0-51.31-21-21-21-21-51.31v-197.69h60v197.69q0 4.62 3.85 8.47 3.84 3.84 8.46 3.84h615.38q4.62 0 8.46-3.84 3.85-3.85 3.85-8.47v-197.69h60v197.69q0 30.31-21 51.31-21 21-51.31 21H172.31Zm-116.92 100v-60h849.22v60H55.39Zm424.61-380Zm-380 10v-60h220q8.31 0 15.81 4.46 7.5 4.47 11.34 12.16L403-453.07l131.08-232.31q3.84-7.08 10.73-10.85Q551.69-700 560-700q8.31 0 15.81 3.77t11.34 12.08l51.7 103.38H860v60H623.85q-9.85 0-18.89-5.11-9.04-5.12-13.65-14.97L558-607.69 426.54-376.15q-3.85 7.69-11.35 11.53-7.5 3.85-15.81 3.85-8.3 0-15.49-4.46-7.2-4.46-11.04-12.15l-71.7-143.39H100Z"/></svg>
  );
  
  const IconoSVG3 = () => (
    <svg className={mainStyle.svgIA} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M757.15-140 536.62-360.54l63.22-63.23 220.54 220.54L757.15-140Zm-553.92 0L140-203.23l277.16-277.15-93.39-93.01-24.92 24.93-44.46-44.85v77l-24.93 24.92-112.92-112.92 24.92-24.92h77l-44.23-43.85 114.31-114.3q16.92-16.93 36.65-24.77 19.73-7.85 41.04-7.85t41.04 7.85q19.73 7.84 36.65 24.77L365-708.46l46.92 46.92L387-636.62l93.38 93.01 96.16-96.16q-6.69-12.15-9.77-25.11-3.08-12.97-3.08-27.27 0-54 37.23-91.23 37.23-37.23 91.23-37.23 12.7 0 24.27 2.23 11.58 2.23 23.27 7.46L648.77-720 720-648.77l90.92-90.92q5.85 11.69 7.77 23.27 1.92 11.57 1.92 24.27 0 54-37.23 91.23-37.23 37.23-91.23 37.23-14.3 0-27.27-2.77-12.96-2.77-25.11-10.08L203.23-140Z"/></svg>
  );
  
  const IconoSVG4 = () => (
    <svg className={mainStyle.svgIA} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M480-122.31q-24.08 0-42.58-12.69-18.5-12.7-25.88-33.31H400q-24.54 0-42.27-17.73Q340-203.77 340-228.31v-131.23q-60.54-36.69-95.27-98.38Q210-519.62 210-590q0-112.92 78.54-191.46T480-860q112.92 0 191.46 78.54T750-590q0 71.61-34.73 132.69T620-359.54v131.23q0 24.54-17.73 42.27-17.73 17.73-42.27 17.73h-11.54q-7.38 20.61-25.88 33.31-18.5 12.69-42.58 12.69Zm-80-106h160v-37.54H400v37.54Zm0-72.92h160V-340H400v38.77ZM392-400h64.15v-116.46l-85.69-85.69L404-635.69l76 76 76-76 33.54 33.54-85.69 85.69V-400H568q54-26 88-76.5T690-590q0-88-61-149t-149-61q-88 0-149 61t-61 149q0 63 34 113.5t88 76.5Zm88-159.69Zm0-40.31Z"/></svg>
  );
  return (
    <div className={mainStyle.main}>
      <div className={mainStyle.central}>
          <img className={mainStyle.logo} src='/img/logo_feelinback_largo.png' alt="" onClick={() => setStep(1)} />
          {step === 1 && (
        <div className={mainStyle.leftSection}>
            <StepOne
              respuesta={respuesta}
              setRespuesta={setRespuesta}
              handleNextStep={handleNextStep}
            />
          </div>
          )}
          {step === 2 && !loading && (
          <div className={mainStyle.leftSection}>
            <StepTwo
              formData={formData}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
            />
          </div>
          )}
          {/* Parte izquierda: se muestra cuando pasamos al step 3, tras enviar el formulario */}
          {step === 3 && (
                    <div className={mainStyle.leftSectionIA}>
            <div className={mainStyle.formularioStepOneIA}>
            <div className={mainStyle.respuestaIzquierda}>
              <h1>Hola {formData.nombre},</h1>
              <h2>Este ha sido tu análisis:</h2>
              <p className={mainStyle.textAreaStepOneRespuesta}>{respuesta}</p>
            </div>
            <div className={mainStyle.creaTuDemo}>
              <p>Crea tu demo en menos de 1 minuto y descubre cómo impulsar el bienestar de tu equipo.</p>
              <h2>¡Esto es solo el comienzo!</h2>
              <button className={mainStyle.btnSecundario} onClick={handleGoToDemo} >Empieza gratis</button>
            </div>
            </div>
          </div>
          )}
          {(step === 1 || step === 2) && !loading && (
          <div className={mainStyle.rightSection}>
            <div className={mainStyle.benefits}>
              <div className={mainStyle.benefit}>
              <h2>¿Qué te aportará la Inteligencia Artificial?</h2>
                <div className={mainStyle.benefitsListTitleSVG}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm.09-100q-91.63 0-155.86-64.14Q260-388.28 260-479.91q0-91.63 64.14-155.86Q388.28-700 479.91-700q91.63 0 155.86 64.14Q700-571.72 700-480.09q0 91.63-64.14 155.86Q571.72-260 480.09-260Zm-.09-60q66 0 113-47t47-113q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-100q-24.54 0-42.27-17.73Q420-455.46 420-480q0-24.54 17.73-42.27Q455.46-540 480-540q24.54 0 42.27 17.73Q540-504.54 540-480q0 24.54-17.73 42.27Q504.54-420 480-420Z"/></svg>
                  <h4>Misión:</h4>
                </div>
                <p>Identifica retos que afectan el desempeño del equipo y refuerza dinámicas positivas.</p>
              </div>
              <div className={mainStyle.benefit}>
                <div className={mainStyle.benefitsListTitleSVG}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M100-580.77v-157.69q0-30.31 21-51.31 21-21 51.31-21h615.38q30.31 0 51.31 21 21 21 21 51.31v157.69h-60v-157.69q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H172.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v157.69h-60Zm72.31 330q-30.31 0-51.31-21-21-21-21-51.31v-197.69h60v197.69q0 4.62 3.85 8.47 3.84 3.84 8.46 3.84h615.38q4.62 0 8.46-3.84 3.85-3.85 3.85-8.47v-197.69h60v197.69q0 30.31-21 51.31-21 21-51.31 21H172.31Zm-116.92 100v-60h849.22v60H55.39Zm424.61-380Zm-380 10v-60h220q8.31 0 15.81 4.46 7.5 4.47 11.34 12.16L403-453.07l131.08-232.31q3.84-7.08 10.73-10.85Q551.69-700 560-700q8.31 0 15.81 3.77t11.34 12.08l51.7 103.38H860v60H623.85q-9.85 0-18.89-5.11-9.04-5.12-13.65-14.97L558-607.69 426.54-376.15q-3.85 7.69-11.35 11.53-7.5 3.85-15.81 3.85-8.3 0-15.49-4.46-7.2-4.46-11.04-12.15l-71.7-143.39H100Z"/></svg>
                  <h4>Diagnóstico:</h4>
                </div>
                <p>Analiza desafíos del equipo, proporcionando información clave para decisiones efectivas.</p>
              </div>
              <div className={mainStyle.benefit}>
                <div className={mainStyle.benefitsListTitleSVG}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M757.15-140 536.62-360.54l63.22-63.23 220.54 220.54L757.15-140Zm-553.92 0L140-203.23l277.16-277.15-93.39-93.01-24.92 24.93-44.46-44.85v77l-24.93 24.92-112.92-112.92 24.92-24.92h77l-44.23-43.85 114.31-114.3q16.92-16.93 36.65-24.77 19.73-7.85 41.04-7.85t41.04 7.85q19.73 7.84 36.65 24.77L365-708.46l46.92 46.92L387-636.62l93.38 93.01 96.16-96.16q-6.69-12.15-9.77-25.11-3.08-12.97-3.08-27.27 0-54 37.23-91.23 37.23-37.23 91.23-37.23 12.7 0 24.27 2.23 11.58 2.23 23.27 7.46L648.77-720 720-648.77l90.92-90.92q5.85 11.69 7.77 23.27 1.92 11.57 1.92 24.27 0 54-37.23 91.23-37.23 37.23-91.23 37.23-14.3 0-27.27-2.77-12.96-2.77-25.11-10.08L203.23-140Z"/></svg>
                  <h4>Solución sugerida:</h4>
                </div>
                <p>Aporta soluciones estratégicas para mejorar el rendimiento y bienestar del equipo.</p>
              </div>
              <div className={mainStyle.benefit}>
                <div className={mainStyle.benefitsListTitleSVG}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M480-122.31q-24.08 0-42.58-12.69-18.5-12.7-25.88-33.31H400q-24.54 0-42.27-17.73Q340-203.77 340-228.31v-131.23q-60.54-36.69-95.27-98.38Q210-519.62 210-590q0-112.92 78.54-191.46T480-860q112.92 0 191.46 78.54T750-590q0 71.61-34.73 132.69T620-359.54v131.23q0 24.54-17.73 42.27-17.73 17.73-42.27 17.73h-11.54q-7.38 20.61-25.88 33.31-18.5 12.69-42.58 12.69Zm-80-106h160v-37.54H400v37.54Zm0-72.92h160V-340H400v38.77ZM392-400h64.15v-116.46l-85.69-85.69L404-635.69l76 76 76-76 33.54 33.54-85.69 85.69V-400H568q54-26 88-76.5T690-590q0-88-61-149t-149-61q-88 0-149 61t-61 149q0 63 34 113.5t88 76.5Zm88-159.69Zm0-40.31Z"/></svg>
                  <h4>Consejo rápido:</h4>
                </div>
                <p>Ofrece recomendaciones prácticas para implementar cambios rápidos en el día a día.</p>
              </div>
            </div>
          </div>
          )}
            {step === 3 && loading && (
              <div className={mainStyle.rightSectionLoader}>
                <div className={mainStyle.loaderContainer}>
                    <Loader />
                </div>
              </div>
            )}

          {/* Mostrar el análisis cuando esté listo */}
          {step === 3 && !loading && challenge && (
            <div className={mainStyle.rightSectionIa}>
            <div className={mainStyle.analisisIA}>
                <div className={mainStyle.analisIALinea}>
                  <h2 className={mainStyle.analisisMovil}>Hola {formData.nombre}, este ha sido tu análisis: </h2>
                  <h2 className={mainStyle.analisisOrdenador} >Análisis IA</h2>
                  <hr />
                </div>
                <div className={mainStyle.analisisIATexto}>
                  <div className={mainStyle.text1}>
                    <IconoSVG1 />  {/* Desafio */}
                    <p>{removeLabel(removeIcon(challenge.Desafio || ''))}</p>
                  </div>
                  <div className={mainStyle.text2}>
                    <IconoSVG2 />  {/* Diagnostico */}
                    <p>{removeLabel(challenge.Diagnostico || '')}</p>
                  </div>
                  <div className={mainStyle.text3}>
                    <IconoSVG3 />  {/* Solucion Sugerida */}
                    <p>{removeLabel(challenge["Solucion Sugerida"] || '')}</p>
                  </div>
                  <div className={mainStyle.text4}>
                    <IconoSVG4 />  {/* Consejo Rapido */}
                    <p>{removeLabel(challenge["Consejo Rapido"] || '')}</p>
                  </div>
                </div>
              </div>
            </div>
            )}

        </div>
      </div>
  );
};

export default Home;
