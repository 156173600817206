
import React, { useState } from "react";
import RoutesComponent from "./routes/routes"; // Asegúrate de que la ruta esté bien ajustada a tu estructura

function App() {

  const [shouldRedirect] = useState(true); // Puedes cambiar la lógica según lo necesites

  return (
    <div>
      <RoutesComponent shouldRedirect={shouldRedirect} />
    </div>
  );
}

export default App;
