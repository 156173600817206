import React from "react";
import mainStyle from '../../styles/mainComponents.module.css'; // Asegúrate de tener este CSS adecuado
import CheckBoxSVG from "../CheckBoxSVG";

const StepTwo = ({ formData, handleInputChange, handleSubmit }) => {

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    handleInputChange({ target: { name, value: checked } });
  }

  return (
    <form className={mainStyle.formularioStepTwo} onSubmit={handleSubmit}>
      <p>Ahora, por favor ingresa tu nombre, apellido y correo:</p>
      <label>Nombre:</label>
      <input
        type="text"
        name="nombre"
        value={formData.nombre}
        onChange={handleInputChange}
        placeholder="Steve"
        required
      />
      <label>Apellido:</label>
      <input
        type="text"
        name="apellido"
        value={formData.apellido}
        onChange={handleInputChange}
        placeholder="Jobs"
        required
      />
      <label>Email:</label>
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        placeholder="name@company.com"
        required
      />
      {/* Checkbox términos y condiciones */}
      <div className={mainStyle.checkboxContainer}>
        <CheckBoxSVG
          id="terms"
          name="terms"
          checked={formData.terms || false}
          onChange={handleCheckboxChange}
          required
        />
        <label>
          Acepto la <a href="https://feelinback.com/politicaprivacidad" className={mainStyle.privacyLink} target="_blank" rel="noreferrer noopener">Política de Privacidad</a> y entiendo que puedo recibir correos sobre esta prueba. 
          ¡No te preocupes, puedes darte de baja cuando quieras!
        </label>
      </div>
      <button type="submit" className={mainStyle.btnSecundario} disabled={!formData.terms}>Continuar</button>
    </form>
  );
};

export default StepTwo;
